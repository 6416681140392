import * as React from "react";
import classNames from "classnames";
import { useUniqueId } from "../../hooks";
import { UniqueIdentifier } from "hooks/uniqueIdentifier";
import TabPanel, { TabPanelType } from "./sub-components/TabPanel";
import {
  useState,
  useRef,
  useEffect,
  useLayoutEffect,
  useCallback,
} from "react";
import Accordion from "../accordion/Accordion";
import AccordionGroup from "../accordion/AccordionGroup";

export interface TabProps {
  /**
   * Array of headings or titles for tabs placed in order of array.
   */
  tabHeadings?: Array<string>;
  /**
   * Currently selected or active tab
   */
  activeTab?: number;
  /**
   * Determines wether or not to auto resize to accordion on mobile.
   *
   * PLEASE NOTE: Turning this off means you will need to implement mobile/web responsiveness yourself.
   */
  autoResize?: boolean;
  /**
   * Call back function for tab change event
   */
  onTabChange?: (
    event:
      | React.MouseEvent<HTMLElement>
      | React.KeyboardEvent<HTMLElement>
      | React.FormEvent<HTMLElement>,
    activeTabIndex: number
  ) => void;
}

/**
 * This is a tab
 * @param {TabProps} props
 */
const Tab: React.FunctionComponent<
  TabProps & React.HTMLAttributes<HTMLDivElement>
> & { TabPanel: TabPanelType } = (props) => {
  const {
    activeTab = 0,
    tabHeadings = [],
    autoResize = true,
    className,
    children,
    onTabChange,
    id,
    ...other
  } = props;

  // Set up ref
  const headingsRef = useRef(new Array(tabHeadings.length));

  // Set up state
  const [currentActiveTab, setCurrentActiveTab] = useState(activeTab);
  const [currentFocusedTab, setCurrentFocusedTab] = useState(activeTab);
  const [switchToMobile, setSwitchToMobile] = useState(false);
  const [tabListWidth, setTabListWidth] = useState(0);

  // Set constants
  const groupIdentifier = useUniqueId("tab");
  const tabListId = groupIdentifier.getChildId("tab-list");
  const tabListRef = useRef<HTMLDivElement>(null);
  const tabIds = generateUniqueIdArray(tabHeadings, groupIdentifier, "tab");
  const tabPanelIds = generateUniqueIdArray(
    tabHeadings,
    groupIdentifier,
    "tab-panel"
  );

  // Set up classes
  let tabClasses = classNames({ "tds-tab": true }, className);

  // Grab any content/html passed into Tab Component so we can add it to accordion when switched
  const tabInnerContentElements = React.Children.toArray(children);

  // Verify and add additional data to TabPanel child elements
  const tabPanelElements = tabInnerContentElements.map((element, index) => {
    if (React.isValidElement(element) && element.type === TabPanel) {
      return React.cloneElement(element, {
        tabIndex: "0",
        role: "tabpanel",
        id: tabPanelIds[index],
        "aria-labelledby": tabIds[index],
        isActive: currentActiveTab === index,
        ...element.props,
      });
    } else {
      return false;
    }
  });

  //======================= Hooks ============================

  const handleResize = useCallback(() => {
    if (window.innerWidth <= tabListWidth) {
      setSwitchToMobile(true);
    } else {
      setSwitchToMobile(false);
    }
  }, [tabListWidth]);

  /**
   * We need to grab the width of the Tab Component on load
   * (as we don't know how long the titles will make it) but before
   * the final paint which is why I'm using useLayoutEffect instead
   * of useEffect, so this way if on load we need to switch to
   * the Accordion component you shouldn't see a flicker.
   */
  useLayoutEffect(() => {
    if (tabListRef.current?.offsetWidth === undefined || !autoResize) return;
    setTabListWidth(tabListRef.current?.offsetWidth + 10);
    handleResize();
  }, [handleResize]);

  useLayoutEffect(() => {
    if (autoResize) window.addEventListener("resize", handleResize);

    return function cleanup() {
      window.removeEventListener("resize", handleResize);
    };
  });

  useEffect(() => {
    const tabListElement = document.getElementById(tabListId);

    tabListElement?.addEventListener("keydown", setUpTabListEventListeners);

    return function cleanup() {
      tabListElement?.removeEventListener(
        "keydown",
        setUpTabListEventListeners
      );
    };
  });

  //======================= Listeners ============================

  function setUpTabListEventListeners(event: KeyboardEvent) {
    const currentElementIndex = currentFocusedTab;
    const maxElementLength = tabHeadings.length;
    const lastTab = maxElementLength - 1;

    if (event.key === "ArrowLeft" && currentFocusedTab > 0) {
      headingsRef.current[currentElementIndex - 1].focus();
      setCurrentFocusedTab(currentFocusedTab - 1);
    } else if (event.key === "ArrowRight" && currentFocusedTab < lastTab) {
      headingsRef.current[currentElementIndex + 1].focus();
      setCurrentFocusedTab(currentFocusedTab + 1);
    } else if (
      (event.key === "ArrowLeft" && currentFocusedTab === 0) ||
      event.key === "End"
    ) {
      headingsRef.current[lastTab].focus();
      setCurrentFocusedTab(lastTab);
    } else if (
      (event.key === "ArrowRight" && currentFocusedTab === lastTab) ||
      event.key === "Home"
    ) {
      headingsRef.current[0].focus();
      setCurrentFocusedTab(0);
    } else if (event.key === "Tab" && currentFocusedTab !== currentActiveTab) {
      /**
       * We need to make sure once the user tabs away from the tabHeadings
       * that the currentFocusedTab is back in sync with the active
       * tab so when they tab back we start from active tab and not
       * where they left off previously.
       */
      setCurrentFocusedTab(currentActiveTab);
    }
  }

  //======================= Render ============================

  return (
    <React.Fragment>
      {!switchToMobile && (
        <div className={tabClasses} id={id} {...other}>
          <span className="tds-tab__tab-list-underline">
            <div
              id={tabListId}
              ref={tabListRef}
              className="tds-tab__tab-list"
              role="tablist"
              aria-label="Numbers"
            >
              {tabHeadings.map((value, index) => {
                return (
                  <div className="tds-tab__tab-button-wrapper" key={index}>
                    <button
                      className={`tds-tab__tab-button${
                        currentActiveTab === index
                          ? " tds-tab__tab-button--active"
                          : ""
                      }`}
                      role="tab"
                      aria-selected={currentActiveTab === index}
                      aria-controls={tabPanelIds[index]}
                      id={tabIds[index]}
                      onClick={(event) => {
                        if (onTabChange) onTabChange(event, index);
                        setCurrentActiveTab(index);
                      }}
                      tabIndex={currentActiveTab === index ? 0 : -1}
                      ref={(element) => (headingsRef.current[index] = element)}
                    >
                      <span>{value}</span>
                    </button>
                    <div
                      className={`tds-tab__tab-button-underline${
                        currentActiveTab === index
                          ? " tds-tab__tab-button-underline--active"
                          : ""
                      }`}
                    ></div>
                  </div>
                );
              })}
            </div>
          </span>
          {tabPanelElements}
        </div>
      )}
      {switchToMobile && (
        <AccordionGroup
          id={id}
          headingLevel={4}
          activeTab={currentActiveTab}
          onAccordionToggle={(event, isToggleOpen, index) => {
            if (onTabChange) onTabChange(event, index);
            if (isToggleOpen) {
              setCurrentActiveTab(index);
            }
          }}
        >
          {tabInnerContentElements.map((element, index) => {
            if (React.isValidElement(element)) {
              return (
                <Accordion key={index} title={tabHeadings[index]}>
                  {element.props.children}
                </Accordion>
              );
            }

            return null;
          })}
        </AccordionGroup>
      )}
    </React.Fragment>
  );
};

/**
 * Create an array of unique id's
 * @param baseNamesArray
 * @param groupIdentifier
 * @param suffix
 */
export function generateUniqueIdArray(
  baseNamesArray: Array<string>,
  groupIdentifier: UniqueIdentifier,
  suffix: string = ""
): Array<string> | [] {
  let idArray: Array<string> = [];
  if (baseNamesArray.length === 0) return idArray;

  baseNamesArray.forEach((value) => {
    idArray.push(
      groupIdentifier.getChildId(
        `${value.replace(" ", "-")}${suffix ? "-" : ""}${suffix}`
      )
    );
  });

  return idArray;
}

Tab.TabPanel = TabPanel;

export default Tab;
